import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../AuthProvider";
import { useNavigate } from "react-router-dom";

function Home() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, [currentUser]);

  return <></>;
}

export default Home;
