import { createSlice } from "@reduxjs/toolkit";

const ChatSlice = createSlice({
  name: "chatSlice",
  initialState: {
    isLoading: false,
    error: null,
    chats: [
      {
        type: 0,
        chat: "Hello there! I'm your friendly Insightio AI assistant. I'm here to help make your experience smooth and enjoyable. Whether you have questions, need information, or just want to chat, I'm here for you. Feel free to ask me anything!",
      },
    ],
  },
  reducers: {
    addChat(state, action) {
      state.chats.push(action.payload);
    },
    addChats(state, action) {
      state.chats = action.payload;
    },
    resetChats(state, action) {
      state.chats = [];
      state.chats.push({
        type: 0,
        chat: "Hello there! I'm your friendly Insightio AI assistant. I'm here to help make your experience smooth and enjoyable. Whether you have questions, need information, or just want to chat, I'm here for you. Feel free to ask me anything!",
      });
    },
  },
});

export { ChatSlice };
export const { addChat, resetChats, addChats } = ChatSlice.actions;
