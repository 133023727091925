import React, { useEffect, useState } from "react";
import "./Payment.css";
import { getAuth } from "firebase/auth";
import { BsCheck2 } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

const Payment = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userStatus = useSelector((state) => state.users.userData);
  const url =
    process.env.REACT_APP_WORKING_ENV == "dev"
      ? process.env.REACT_APP_NODE_SERVER_DEV_BASE_URL
      : process.env.REACT_APP_NODE_SERVER_PROD_BASE_URL;
  const plans = [
    {
      name: "Free",
      planId: "",
      price: "Free",
      functions: [
        { count: 2, type: "Projects" },
        { count: 6, type: "Interviews" },
        { count: 3, type: "Chats" },
        { count: -1, type: "Insights" },
        { count: null, type: "24/7 Support" },
      ],
      description: "This Package suits users with lower volumes of user calls",
    },

    {
      name: "Pro",
      planId: "price_1O8HyhB0tQd2CCfRKoz5YTHL",
      price: "19",
      functions: [
        { count: 5, type: "Projects" },
        { count: 20, type: "Interviews" },
        { count: 10, type: "Chats" },
        { count: -1, type: "Insights" },
        { count: null, type: "24/7 Support" },
      ],
      description:
        "This package is tailored for users managing high call volumes",
    },

    {
      name: "Enterprise",
      planId: "",
      price: "Custom",
      functions: [
        { count: -1, type: "Projects" },
        { count: -1, type: "Interviews" },
        { count: null, type: "Custom Reports" },
        { count: null, type: "Unlimited Insights" },
        { count: null, type: "Dedicated Support" },
      ],
      description: "Bulk data processing and custom reports",
    },
  ];

  const checkout = (plan) => {
    setIsLoading(true);
    fetch(`${url}/api/v1/create-subscription-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ plan: plan, customerId: userId }),
    })
      .then(async (res) => {
        if (res.ok) return res.json();
        // console.log("res", res);
        const json = await res.json();
        return await Promise.reject(json);
      })
      .then(({ session }) => {
        window.location = session.url;
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e.error);
      });
  };

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        setUsername(user.displayName);
      } else {
        setUserId("");
        setUsername("");
      }
    });
  }, [userId]);
  return (
    <div className="payment-main">
      <div className="payment-wrapper">
        <div className="payment-headings">
          <h2>Plans and Pricing</h2>
          <div className="subscription-downgrade-box">
            <p>
              For downgrading and cancellation of subscription please contact
              our support team.
            </p>
            <button
              onClick={() => {
                navigate("/contact");
              }}
            >
              Cancel Subscription
            </button>
          </div>
        </div>
        <div className="payment-box-container">
          {plans.map((plan, index) => {
            return (
              <div className="payment-box" key={index}>
                {userStatus?.member_ship_status.toLowerCase() ===
                  plan.name.toLowerCase() && (
                  <div className="current-plan-tag">
                    <p>Current Plan</p>
                  </div>
                )}

                <h3
                  style={{
                    color: index === 1 ? "white" : "#939eae",
                  }}
                >
                  {plan.name}
                </h3>
                <h2>
                  {plan.price === "Free" || plan.price === "Custom"
                    ? plan.price
                    : `$${plan.price}`}
                  {plan.price !== "Free" && plan.price !== "Custom" && (
                    <span
                      style={{
                        color: index === 1 ? "white" : "#939eae",
                      }}
                    >
                      /month
                    </span>
                  )}
                </h2>
                <p>{plan.description}</p>
                <p
                  style={{
                    color: index === 1 ? "white" : "black",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  What's Included:
                </p>

                <div className="subscription-values">
                  {plan.functions.map((func, ind) => {
                    return (
                      <div className="plan-values" key={ind}>
                        <div
                          className="tick-circle"
                          style={{ background: index === 1 && "white" }}
                        >
                          <BsCheck2 color={index === 1 ? "black" : "white"} />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            width: "100%",
                          }}
                        >
                          <span
                            style={{
                              color: index === 1 ? "white" : "#12141D",
                            }}
                          >
                            {func.count === -1 ? "Unlimited" : func.count}
                          </span>
                          <span
                            style={{
                              color: index === 1 ? "white" : "#12141D",
                            }}
                          >
                            {func.type}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {userStatus?.member_ship_status.toLowerCase() ===
                plan.name.toLowerCase() ? (
                  <button>Current Plan</button>
                ) : (
                  <button
                    onClick={() => {
                      if (plan.name === "Pro") {
                        checkout(Number(plan.price));
                      }
                      if (plan.name === "Enterprise") {
                        navigate("/contact");
                      }
                    }}
                  >
                    {isLoading && plan.name === "Pro" ? (
                      <RotatingLines width="23" strokeColor="black" />
                    ) : (
                      "Get Started"
                    )}
                  </button>
                )}
                {index === 1 && (
                  <p
                    style={{
                      textAlign: "center",
                      margin: 0,
                      marginTop: "10px",
                    }}
                  >
                    you can cancel anytime
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Payment;
