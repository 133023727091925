import "./App.css";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
import { Route, Routes, useLocation } from "react-router-dom";
import { AuthProvider } from "./AuthProvider";
import Home from "./components/Home/Home";
import SignUp from "./components/SignUp/SignUp";
import ProcessedTheme from "./components/ProcessedTheme/ProcessedTheme";
import Project from "./components/Project/Project";
import SideBar from "./components/SideBar/SideBar";
import Header from "./components/Header/Header";
import UserInput from "./components/UserInput/UserInput";
import DownloadAble from "./components/DownloadAble/DownloadAble";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Payment from "./components/Payments/Payment";
import Success from "./components/Success/Success";
import { useEffect, useState } from "react";
import Contact from "./components/Contact/Contact";
import PaymentFailure from "./components/PaymentFailure/PaymentFailure";
import TermsCondition from "./components/TermsConditions/TermsCondition";

function App() {
  const location = useLocation();
  const [isWebsiteLoaded, setIsWebsiteLoaded] = useState(false);
  const pathsWithoutSidebar = [
    "/login",
    "/signup",
    "/download",
    "/reset-password",
    "/terms-conditions",
  ];
  const shouldShowSidebar = !pathsWithoutSidebar.includes(location.pathname);

  useEffect(() => {
    window.onload = () => {
      // console.log("website loaded");
      setIsWebsiteLoaded(true);
    };
  }, []);
  // console.log("called");
  return (
    <AuthProvider>
      <div className="App">
        <div className="main">
          <div className="sidebar-h">
            <Header />
            {shouldShowSidebar && <SideBar />}
          </div>
          <div className="routes">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/processed-data" element={<ProcessedTheme />} />
              <Route path="/project/:projectId" element={<Project />} />
              <Route path="/user-input" element={<UserInput />} />
              <Route path="/processed-theme" element={<ProcessedTheme />} />
              <Route path="/download" element={<DownloadAble />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/payments" element={<Payment />} />
              <Route path="/success" element={<Success />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/payment-fail" element={<PaymentFailure />} />
              <Route path="/terms-conditions" element={<TermsCondition />} />
            </Routes>
          </div>
        </div>
      </div>
    </AuthProvider>
  );
}

export default App;
