import React, { useState } from "react";
import "./ResetPassword.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const resetPasswordHandler = async (e) => {
    e.preventDefault();
    handleOpen();
    await sendPasswordResetEmail(auth, email)
      .then((data) => {
        toast.success("Check Your Email", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/login");
        }, 3000);
        handleClose();
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        handleClose();
      });
  };
  return (
    <div className="reset-password-main">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="reset-password-box">
        <div className="forgot-password-heading">
          <h2>Forgot your password ?</h2>
          <p>
            Enter your email address below and we will send you a link to reset
            your password.
          </p>
        </div>
        <form onSubmit={resetPasswordHandler}>
          <TextField
            type="email"
            required
            id="outlined-basic"
            label="Email"
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
          <button type="submit">Send Reset Link</button>
        </form>
        <div className="reset-password-navigation">
          <p onClick={() => navigate("/signup")}>Create account here</p>
          <p>|</p>
          <p onClick={() => navigate("/login")}>Login here</p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
