import React, { useEffect } from "react";
import "./DownloadAble.css";
import Header from "../Header/Header";
import { useSelector } from "react-redux";
import UserProfiles from "../UserProfiles/UserProfiles";
import Patterns from "../Patterns/Patterns";
import Analysis from "../Analysis/Analysis";
import { usePDF } from "react-to-pdf";

const DownloadAble = () => {
  const projectName = useSelector((state) => state.users.projectName);
  const { toPDF, targetRef } = usePDF({ filename: `${projectName}.pdf` });

  return (
    <div
      className="downloadable-main"
      ref={targetRef}
      style={{ marginTop: "50px" }}
    >
      <Header />
      <div className="download-content">
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button className="project-download-button" onClick={() => toPDF()}>
            Download
          </button>
        </div>
        <h1 style={{ marginTop: "30px", textTransform: "uppercase" }}>
          {projectName}
        </h1>
        <div className="download-user-profiles">
          <UserProfiles exp={true} />
        </div>
        <div className="download-patterns" style={{ marginTop: "20px" }}>
          <h2>Patterns</h2>
          <Patterns />
        </div>
        <div className="download-analysis" style={{ marginTop: "20px" }}>
          <h2>Analysis</h2>
          <Analysis />
        </div>
      </div>
    </div>
  );
};

export default DownloadAble;
