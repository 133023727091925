import React, { useEffect, useState } from "react";
import "./UserInput.css";
import TextData from "../TextData/TextData";
import AVData from "../AVData/AVData";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const UserInput = () => {
  const navigate = useNavigate();
  const [preferedInput, setPreferedInput] = useState(0);
  const projectName = useSelector((state) => state.users.projectName);
  const useruid = localStorage.getItem("userId");

  useEffect(() => {
    if (!useruid) {
      navigate("/login");
    }
    if (!projectName) {
      navigate("/dashboard");
    }
  }, []);

  // console.log(preferedInput);

  return (
    <div className="user-input-main">
      <div className="user-project-name">
        <p>{projectName}</p>
      </div>
      <div className="user-input-selection">
        <div
          className="audio-video-data"
          onClick={() => setPreferedInput(0)}
          id="audio-video-data"
          style={{
            background: preferedInput === 0 ? "#6D4697" : "white",
            color: preferedInput === 0 && "white",
          }}
        >
          <p>Video/Audio Files</p>
        </div>
        <div
          className="text-data"
          onClick={() => setPreferedInput(1)}
          id="text-data"
          style={{
            background: preferedInput == 1 ? "#6D4697" : "white",
            color: preferedInput === 1 && "white",
          }}
        >
          <p>Copy Paste</p>
        </div>
      </div>
      <div className="user-data-input">
        {preferedInput ? <TextData /> : <AVData />}
      </div>
    </div>
  );
};

export default UserInput;
