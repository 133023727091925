import React, { useEffect, useState } from "react";
import "./ProcessedTheme.css";
import { useSelector } from "react-redux";
import { setIsLoading } from "../../store/slices/UserProfileSlice";
import { v4 as uuidv4 } from "uuid";
import { addUserProfile } from "../../store/slices/UserProfileSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import BeautifulTabComponent from "../TabComponent/TabComponent";
import { setResponseRecieved } from "../../store/slices/UserDataSlice";
import { useNavigate } from "react-router-dom";
import { setProjectId } from "../../store/slices/UserSlice";
import DownloadAble from "../DownloadAble/DownloadAble";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import useUserData from "../../hooks/useUserData";
import NewTabComponent from "../NewTabComponent/NewTabComponent";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "100%",
  maxHeight: "80%",
  overflow: "auto",
  borderRadius: "8px",
};

const ProcessedTheme = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projectName = useSelector((state) => state.users.projectName);
  const userData = useSelector((state) => state.userData.userData);
  const useruid = localStorage.getItem("userId");
  // const userProfiles = useSelector((state) => state.userProfiles.userProfiles);
  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isComplete = useSelector((state) => state.userProfiles.isComplete);
  // const isLoading = useSelector((state) => state.userProfiles.isLoading);
  const { updateUserStatus, setUserProject, resetGlobalStates } = useUserData();
  const url =
    process.env.REACT_APP_WORKING_ENV == "dev"
      ? process.env.REACT_APP_DJANGO_LOCAL_SERVER
      : process.env.REACT_APP_DJANGO_PROD_SERVER;

  const api = axios.create({
    baseURL: url,
  });

  useEffect(() => {
    if (!userData) {
      navigate("/dashboard");
    }
    if (!useruid) {
      navigate("/login");
    }

    const apiCalls = userData?.map((ele, index) => {
      return api.post("/api/get-user-profiles/", {
        userId: ele.id,
        data: ele.value,
        username: ele.name,
      });
    });

    let completedApiCalls = 0; // Initialize the counter

    apiCalls?.forEach((apiCall, index) => {
      apiCall
        .then(async (res) => {
          const r = res.data;
          dispatch(addUserProfile(r)); // Push the response to the global state
          completedApiCalls++; // Increment the counter

          if (completedApiCalls === apiCalls.length) {
            // Last API call has been resolved
            const project_id = uuidv4();
            dispatch(setProjectId(project_id));
            const projRef = doc(db, "projects", project_id);
            await setDoc(projRef, {
              project_id: project_id,
              chats: [],
            });
            dispatch(setResponseRecieved(true));
          }
        })
        .catch((error) => {
          completedApiCalls++;
          console.error("An error occurred:", error);
          dispatch(setIsLoading(false));
          toast.error("Some Error Occured Sorry For This Inconvinience", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          resetGlobalStates();
          navigate("/dashboard");
        });
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isComplete) {
      updateUserStatus();
      setUserProject();
    }
  }, [isComplete]);

  return (
    <div className="processed-theme-main-h">
      <div className="project-name">{projectName}</div>
      <div className="processed-theme-container">
        {/* {!isLoading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button className="project-download-button" onClick={handleOpen}>
              Download
            </button>
          </div>
        )} */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <DownloadAble />
            </Box>
          </Fade>
        </Modal>
        {/* <BeautifulTabComponent active={true} /> */}
        <NewTabComponent active={true} />
      </div>
    </div>
  );
};

export default ProcessedTheme;
