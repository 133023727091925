import React, { useEffect, useRef, useState } from "react";
import "./ProcessedChat.css";
import { AiOutlineSend } from "react-icons/ai";
import AiChat from "../AiChat/AiChat";
import UserChat from "../UserChat/UserChat";
import TypingLoader from "../TypingLoader/TypingLoader";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addChat } from "../../store/slices/ChatSlice";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import useUserData from "../../hooks/useUserData";
import { getAuth } from "firebase/auth";

const ProcessedChat = () => {
  const chatContainer = useRef(null);
  const [selectedProfile, setSelectedProfile] = useState("All Users");
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const chats = useSelector((state) => state.chatSlice.chats);
  const projectId = useSelector((state) => state.users.projectId);
  const { createBodyData } = useUserData();
  const userData = useSelector((state) => state.users.userData);
  const user = doc(db, "users", getAuth().currentUser.uid);

  const url =
    // eslint-disable-next-line
    process.env.REACT_APP_WORKING_ENV == "dev"
      ? process.env.REACT_APP_DJANGO_LOCAL_SERVER
      : process.env.REACT_APP_DJANGO_PROD_SERVER;

  function chatSubmitHandler(e) {
    e.preventDefault();
    setIsLoading(true);
    saveChat(1, userInput);
    setUserInput("");
    if (userData.number_of_chats > 0) {
      // console.log("here");

      const data = {
        data: JSON.stringify(createBodyData()),
        question: userInput,
        projectId: projectId,
      };
      console.log(data);
      axios.post(`${url}/api/chat/`, data).then(async (res) => {
        setIsLoading(false);
        saveChat(0, res.data.answer);
        await updateDoc(user, {
          number_of_chats: userData.number_of_chats - 1,
        }).catch((err) => {
          setIsLoading(false);
          saveChat(0, "Sorry there was some issue from my backend");
        });
      });
    } else {
      saveChat(
        0,
        "Sorry no chats remaining kindly upgrade your plan to do more chats"
      );
      setIsLoading(false);
    }
    // if (selectedProfile) {
    //   const data = {
    //     data: JSON.stringify(createBodyData()),
    //     question: userInput,
    //     projectId: projectId,
    //   };
    //   axios.post(`${url}/api/chat/`, data).then(async (res) => {
    //     setIsLoading(false);
    //     saveChat(0, res.data.answer);
    //   });
    // } else {
    //   setIsLoading(false);
    //   dispatch(
    //     addChat({
    //       type: 0,
    //       chat: "Please Select the User Profile from above 😊",
    //     })
    //   );
    // }
  }

  async function saveChat(type, chat) {
    dispatch(addChat({ type: type, chat: chat }));
    const ProjRef = doc(db, "projects", projectId);
    await updateDoc(ProjRef, {
      chats: arrayUnion({ type: type, chat: chat }),
    });
  }
  useEffect(() => {
    if (chatContainer.current) {
      chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
    }
  }, [chats]);

  // console.log(chats);
  return (
    <>
      <div className="processed-chat-container">
        {/* <div className="select-user-profile">
          <select
            value={selectedProfile}
            onChange={(e) => setSelectedProfile(e.target.value)}
          >
            <option value={"all"}>All Users</option>
          </select>
        </div> */}
        <div className="main-chat-area" ref={chatContainer}>
          {chats?.map((chat, index) => {
            return chat?.type ? (
              <UserChat message={chat?.chat} key={index} />
            ) : (
              <AiChat message={chat?.chat} key={index} />
            );
          })}
          {isLoading && <TypingLoader />}
        </div>
        <div className="user-chat-input-field">
          <form onSubmit={chatSubmitHandler}>
            <input
              type="text"
              placeholder="Type your query here..."
              onChange={(e) => setUserInput(e.target.value)}
              value={userInput}
            />
            <button type="submit" disabled={userInput.length === 0}>
              <AiOutlineSend size={20} color="black" fill="white" />
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ProcessedChat;
