import React, { useEffect, useState } from "react";
import "./AVData.css";
import { BsPaperclip } from "react-icons/bs";
// import ReactModal from "react-modal";
// import TabNavigation from "../TabNavigation/TabNavigation";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  // addUserData,
  removeUrls,
  setUserData,
} from "../../store/slices/UserDataSlice";
// import { addFile, setIsLoading } from "../../store/slices/UserProfileSlice";
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { setIsLoading } from "../../store/slices/UserProfileSlice";
import CloudinaryUploadWidget from "../../CloudinaryWidget/CloudinaryWidget";
import UploadedFile from "../UploadedFile/UploadedFile";

// const customStyles = {
//   overlay: {
//     zIndex: "1000",
//   },
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//     maxWidth: "900px",
//     width: "100%",
//     backgroundColor: "white",
//     borderRadius: "8px",
//     boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
//     padding: "40px",
//     margin: "auto",
//     height: "600px",
//     overflow: "hidden",
//   },
// };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "762px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  p: 4,
  height: "300px",
};

const AVData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [open, setOpen] = React.useState(false);
  const urls = useSelector((state) => state.userData.urls);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const userData = useSelector((state) => state.users.userData);
  const allowedFormats = [
    ".mp4",
    ".mp3",
    ".wav",
    ".mp4",
    ".avi",
    ".mkv",
    ".mov",
    ".mpeg",
    ".m4a",
  ];

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);
    setFiles([...files, ...newFiles]);
  };

  const onUploadHandler = () => {
    setIsSubmitted(true);
  };

  const onSubmitHandler = () => {
    handleOpen();
    const url =
      process.env.REACT_APP_WORKING_ENV === "dev"
        ? process.env.REACT_APP_DJANGO_LOCAL_SERVER
        : process.env.REACT_APP_DJANGO_PROD_SERVER;
    const requests = urls.map((file) =>
      axios.post(
        `${url}/api/voice-transcription/`,
        {
          urls: [
            {
              url: file.url,
              userId: file.userId,
            },
          ],
        },
        { timeout: 600000 }
      )
    );
    const data = [];
    Promise.allSettled(requests)
      .then((results) => {
        console.log(results);
        results.forEach((result) => {
          if (result.status === "fulfilled") {
            data.push(result.value.data.response[0]);
          } else {
            const err = result.reason;
            console.log(err.message);
          }
        });
        handleClose();
      })
      .catch((error) => {
        handleClose();
        dispatch(removeUrls());
        console.error("Error with Promise.allSettled:", error);
        setLoading(false);
      })
      .finally(() => {
        dispatch(setUserData(data));
        dispatch(removeUrls());
        dispatch(setIsLoading(true));
        handleClose();
        navigate("/processed-data");
      });
    // let apiCount = 0;
    // urls.forEach((file) => {
    //   axios
    //     .post(
    //       `${url}/api/voice-transcription/`,
    //       {
    //         urls: [
    //           {
    //             url: file.url,
    //             userId: file.userId,
    //           },
    //         ],
    //       },
    //       { timeout: 600000 }
    //     )
    //     .then((res) => {
    //       apiCount++;
    //       console.log(res.data.response);
    //       dispatch(addUserData(res.data.response[0]));
    //       if (apiCount === urls.length) {
    //         console.log("all apis resolved");
    //         dispatch(removeUrls());
    //         dispatch(setIsLoading(true));
    //         handleClose();
    //         navigate("/processed-data");
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err.message);
    //       handleClose();
    //       dispatch(removeUrls());
    //       console.log(err.message);
    //       toast.error(err.message, {
    //         position: "top-right",
    //         autoClose: 2000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "colored",
    //       });
    //       setLoading(false);
    //     });
    // });
    // axios
    //   .post(
    //     `${url}/api/voice-transcription/`,
    //     { urls: urls },
    //     { timeout: 600000 }
    //   )
    //   .then((res) => {
    // console.log(res.data);
    // dispatch(removeUrls());
    // dispatch(setUserData(res.data.response));
    // dispatch(setIsLoading(true));
    // handleClose();
    // navigate("/processed-data");
    //   })
    //   .catch((err) => {
    //     if (axios.isCancel(err)) {
    //       console.log("timedout");
    //     }
    // handleClose();
    // dispatch(removeUrls());
    // console.log(err.message);
    // toast.error(err.message, {
    //   position: "top-right",
    //   autoClose: 2000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "colored",
    // });
    // setLoading(false);
    //   });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const deleteFile = (i) => {
    const lst = [];
    // eslint-disable-next-line
    files.map((ele, index) => {
      if (index !== i) {
        lst.push(ele);
      }
    });
    setFiles(lst);
  };

  const handleFileSelect = (e) => {
    const newFiles = Array.from(e.target.files);
    const allValidFormats = newFiles.every((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return allowedFormats.includes("." + extension);
    });

    if (allValidFormats) {
      setFiles([...files, ...newFiles]);
    } else {
      toast.error(
        "Please select only .mp4, .mp3, .avi, .mkv, .mov, .mpeg, .m4a or .wav files.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      e.target.value = ""; // Reset the input field to clear invalid files
    }
  };

  useEffect(() => {
    console.log(urls.length);
    console.log(files.length);
    if (urls.length === files.length && files.length !== 0) {
      setIsUploaded(true);
    }
  }, [urls]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {/* <ReactModal
        isOpen={isModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="modal-body-av">
          <h2>Label Users</h2>
          <div className="file-names-av">
            <TabNavigation files={files} />
          </div>
        </div>
      </ReactModal> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="trancription-loading-modal">
              <span>
                Your files are being transcribed now. This can take a few
                minutes, please leave the tab open
              </span>
              <div className="center">
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <div className="file-drop-container">
        {files.length === 0 ? (
          <div
            className="drop-area"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            Drop files here or{" "}
            <label htmlFor="fileInput" className="file-input-label">
              click here to select files
            </label>
            <input
              type="file"
              id="fileInput"
              multiple
              accept=".mp4, .mp3, .wav, .mp4, .avi, .mkv, .mov, .mpeg, .m4a"
              onChange={handleFileSelect}
            />
          </div>
        ) : (
          <div className="file-list">
            <h3 style={{ marginBottom: "30px" }}>Uploaded Files:</h3>
            <ul>
              {files.map((file, index) => {
                return (
                  <UploadedFile
                    key={index}
                    file={file}
                    deletefile={deleteFile}
                    index={index}
                    isSubmitted={isSubmitted}
                  />
                );
              })}
            </ul>
          </div>
        )}
      </div>
      <div className="bottom-buttons">
        {files.length > 0 && !isSubmitted ? (
          <div className="upload-more-files">
            <label htmlFor="fileInput">
              <BsPaperclip size={25} color="#109CF1" />
              <p>Upload more files</p>
            </label>
            <input
              type="file"
              id="fileInput"
              multiple
              accept=".mp4, .mp3, .wav, .mp4, .avi, .mkv, .mov, .mpeg, .m4a"
              onChange={handleFileSelect}
              style={{ display: "none" }}
            />
          </div>
        ) : (
          <span></span>
        )}
        <div className="continue-button">
          {!isUploaded ? (
            <button
              onClick={() => onUploadHandler()}
              disabled={files.length === 0}
            >
              {isLoading ? (
                <RotatingLines width="20" strokeColor="white" />
              ) : (
                "Continue"
              )}
            </button>
          ) : (
            <button onClick={onSubmitHandler}>Analyze</button>
          )}
        </div>
      </div>
    </>
  );
};

export default AVData;
