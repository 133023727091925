import { createSlice } from "@reduxjs/toolkit";

const UserSlice = createSlice({
  name: "user",
  initialState: {
    projectName: null,
    projectId: null,
    isProject: false,
    userData: null,
    newUser: false,
  },
  reducers: {
    setNewUser(state, action) {
      state.newUser = action.payload;
    },
    setProject(state, action) {
      state.projectName = action.payload;
    },
    setProjectId(state, action) {
      state.projectId = action.payload;
    },
    setIsProject(state, action) {
      state.isProject = true;
    },
    setUser(state, action) {
      state.userData = action.payload;
    },
    resetProjectName(state, action) {
      state.projectName = null;
      state.projectId = null;
      state.isProject = false;
    },
    resetUserData(state, action) {
      state.userData = null;
    },
  },
});

export { UserSlice };
export const {
  setProject,
  resetProjectName,
  setProjectId,
  setIsProject,
  setUser,
  resetUserData,
  setNewUser,
} = UserSlice.actions;
