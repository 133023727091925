import { configureStore } from "@reduxjs/toolkit";
import { UserSlice } from "./slices/UserSlice";
import { userProfileSlice } from "./slices/UserProfileSlice";
import { userDataSlice } from "./slices/UserDataSlice";
import { UserActionSlice } from "./slices/UserActionSlice";
import { ChatSlice } from "./slices/ChatSlice";
import thunk from "redux-thunk";

const store = configureStore({
  reducer: {
    users: UserSlice.reducer,
    userProfiles: userProfileSlice.reducer,
    userData: userDataSlice.reducer,
    userAction: UserActionSlice.reducer,
    chatSlice: ChatSlice.reducer,
  },
  middleware: [thunk],
});

export default store;
