import React, { useState } from "react";
import "./TextData.css";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../store/slices/UserDataSlice";
import { AiOutlinePlus } from "react-icons/ai";
import { setIsLoading } from "../../store/slices/UserProfileSlice";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const TextData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fields, setFields] = useState([{ id: 1, name: "User 1", value: "" }]);
  const userData = useSelector((state) => state.users.userData);
  const maxUserInputs = userData.member_ship_status === "free" ? 2 : 6;

  const addField = () => {
    const newField = {
      id: fields.length + 1,
      name: `User ${fields.length + 1}`,
      value: "",
    };
    setFields([...fields, newField]);
  };

  const removeField = (id) => {
    const updatedFields = fields.filter((field) => field.id !== id);
    setFields(updatedFields);
  };

  const handleNameChange = (id, newName) => {
    const updatedFields = fields.map((field) =>
      field.id === id ? { ...field, name: newName } : field
    );
    setFields(updatedFields);
  };

  const handleValueChange = (id, newValue) => {
    const updatedFields = fields.map((field) =>
      field.id === id ? { ...field, value: newValue } : field
    );
    setFields(updatedFields);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (fields.length <= userData.number_of_interviews) {
      dispatch(setUserData(fields));
      dispatch(setIsLoading(true));
      navigate("/processed-theme");
    } else {
      const message =
        userData.number_of_interviews > 0
          ? "Interviews Out of Limit"
          : "Interviews Limit Reached";
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <>
      <div className="dynamic-input-container">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <form onSubmit={submitHandler} id="user-inputs-form">
          {fields.map((field, index) => (
            <div key={field.id} className="input-field">
              <div className="user-name-field">
                <label htmlFor="user-name">Participant Name</label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  defaultValue={`User ${index + 1}`}
                  required
                  id="user-name"
                  onChange={(e) => handleNameChange(field.id, e.target.value)}
                />
              </div>
              <textarea
                placeholder="Paste Your Text Here"
                value={field.value}
                required
                onChange={(e) => handleValueChange(field.id, e.target.value)}
              />
              {fields.length > 1 && (
                <button onClick={() => removeField(field.id)}>
                  <RxCross1 size={20} />
                </button>
              )}
            </div>
          ))}
          {fields.length < maxUserInputs &&
          fields.length < userData.number_of_interviews ? (
            <button className="add-button" onClick={addField}>
              Add More <AiOutlinePlus size={22} />
            </button>
          ) : (
            <Link to="/payments">Upgrade to pro plan to get more access</Link>
          )}
        </form>
      </div>
      <div className="submit-button">
        <button type="submit" form="user-inputs-form">
          Analyze
        </button>
      </div>
    </>
  );
};

export default TextData;
