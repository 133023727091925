import React, { useEffect, useState } from "react";
import "./Success.css";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";

const Success = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const url =
    process.env.REACT_APP_WORKING_ENV == "dev"
      ? process.env.REACT_APP_NODE_SERVER_DEV_BASE_URL
      : process.env.REACT_APP_NODE_SERVER_PROD_BASE_URL;

  const handlePaymentSuccess = () => {
    fetch(`${url}/api/v1/payment-success`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({ sessionId: sessionId, firebaseId: userId }),
    })
      .then(async (res) => {
        if (res.ok) return res.json();
        const json = await res.json();
        return await Promise.reject(json);
      })
      .then((data) => {
        // console.log(data.message);
        navigate("/dashboard");
      })
      .catch((e) => {
        console.log(e.error);
      });
  };

  // console.log(sessionId);

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        const userRef = doc(db, "users", user.uid);
        const data = onSnapshot(userRef, (snap) => {
          if (snap.exists()) {
            setSessionId(snap.data().subscription.sessionId);
          } else {
            console.log("no document existed");
          }
        });
      }
    });
  });
  return (
    <div className="success-page-main">
      <h1>Payment Successfull</h1>
      <button onClick={handlePaymentSuccess}>Proceed</button>
    </div>
  );
};

export default Success;
