import React, { useContext, useEffect, useState } from "react";
import "./SideBar.css";
import { auth, db } from "../../firebase";
import { signOut } from "firebase/auth";
import { AuthContext } from "../../AuthProvider";
import { useNavigate } from "react-router-dom";
import dummy_image from "../../assets/images/dummy_avatar.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { resetUserData } from "../../store/slices/UserDataSlice";
import { resetUserProfile } from "../../store/slices/UserProfileSlice";
import { resetProjectName, setUser } from "../../store/slices/UserSlice";
import { resetChats } from "../../store/slices/ChatSlice";
import { doc, onSnapshot } from "firebase/firestore";
import LogoutIcon from "../../assets/icons/logout.png";
import { BsChevronRight } from "react-icons/bs";
import { BsBag } from "react-icons/bs";
import { GoProject } from "react-icons/go";
import { RiHeadphoneLine } from "react-icons/ri";

export default function SideBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const isLoading = useSelector((state) => state.userProfiles.isLoading);
  const [memberShipStatus, setMemberShipStatus] = useState(null);
  const userData = useSelector((state) => state.users.userData);
  const [currentSelectedButton, setCurrentSelectedButton] = useState(0);

  useEffect(() => {
    if (currentUser) {
      const docRef = doc(db, "users", currentUser.uid);
      // eslint-disable-next-line
      onSnapshot(docRef, (snap) => {
        if (snap.exists()) {
          dispatch(setUser(snap.data()));
          setMemberShipStatus(snap.data().member_ship_status);
        }
      });
    }
  }, [currentUser]);

  return (
    <div className="sidebar-container">
      <div className="project-actions">
        <button
          disabled={isLoading}
          onClick={() => {
            setCurrentSelectedButton(0);
            dispatch(resetUserData());
            dispatch(resetUserProfile());
            dispatch(resetProjectName());
            dispatch(resetChats());
            navigate("/dashboard");
          }}
          style={{
            background: currentSelectedButton === 0 ? "#6d4697" : "white",
            color: currentSelectedButton === 0 ? "white" : "#53545c",
          }}
        >
          <GoProject size={20} background="#53545c" /> My Projects
        </button>
        {/* <button
          onClick={() => {
            setCurrentSelectedButton(1);
          }}
          style={{
            background: currentSelectedButton === 1 ? "#6d4697" : "white",
            color: currentSelectedButton === 1 ? "white" : "#53545c",
          }}
        >
          <BsChatDots size={20} /> Chat
        </button> */}
        <button
          disabled={isLoading}
          onClick={() => {
            setCurrentSelectedButton(2);
            navigate("/payments");
            dispatch(resetUserData());
            dispatch(resetUserProfile());
            dispatch(resetProjectName());
            dispatch(resetChats());
          }}
          style={{
            background: currentSelectedButton === 2 ? "#6d4697" : "white",
            color: currentSelectedButton === 2 ? "white" : "#53545c",
          }}
        >
          <BsBag size={20} fontWeight={"bold"} /> Plans
        </button>
        {/* {isLoading && (
          <button
            onClick={() => {
              setCurrentSelectedButton(3);
            }}
            style={{
              background: "#6d4697",
              color: "white",
            }}
          >
            <MdOutlineWbCloudy size={20} /> New Project{" "}
            <CircularProgress color="inherit" size={20} />
          </button>
        )} */}
      </div>
      {currentUser && (
        <div className="user-profile">
          <div
            className="contact-support-box"
            onClick={() => {
              if (!isLoading) {
                navigate("/contact");
              }
            }}
          >
            <RiHeadphoneLine size={20} />
            <p>Contact Support</p>
          </div>
          <div
            className="sidebar-plan-type"
            onClick={() => {
              if (!isLoading) {
                setCurrentSelectedButton(2);
                navigate("/payments");
                dispatch(resetUserData());
                dispatch(resetUserProfile());
                dispatch(resetProjectName());
                dispatch(resetChats());
              }
            }}
          >
            <div className="upper-plan-type">
              <BsBag size={20} fontWeight={"bold"} />
              {/* <img src={BreifCaseIcon} /> */}
              <p>{memberShipStatus} Plan</p>
              <span>
                Upgrade <BsChevronRight color="#6E7079" />
              </span>
            </div>
            <div className="lower-plan-type">
              <p>{userData?.number_of_projects} projects - remaining</p>
              <p>{userData?.number_of_interviews} Interviews - remaining</p>
              <p>{userData?.number_of_chats} Chats - remaining</p>
            </div>
          </div>
          <div className="user-box">
            <div className="user-image">
              <img
                src={
                  currentUser?.photoURL ? currentUser?.photoURL : dummy_image
                }
                alt={currentUser.displayName}
              />
            </div>
            <div className="user-data">
              <button
                className="logout-button"
                onClick={() => {
                  dispatch(resetUserData());
                  dispatch(resetUserProfile());
                  dispatch(resetProjectName());
                  dispatch(resetUserData());
                  localStorage.removeItem("userId");
                  signOut(auth);
                  navigate("/login");
                }}
              >
                <img src={LogoutIcon} alt="logout arrow" /> Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
