import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const PatternsIterable = ({ heading, patterns, iterable, handleOpen,index }) => {
  return (
    <div className="patterns-trends-box">
      <div className="patterns-header">
        <h2>{heading}</h2>
        {iterable instanceof Array && iterable?.length > 5 && (
          <button onClick={() => handleOpen(patterns[index], index)}>See all</button>
        )}
      </div>
      {!patterns ? (
        <Box sx={{ width: "100%" }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      ) : (
        <ul>
          {iterable instanceof Array && iterable?.map((data, index) => {
            return (
              index <= 4 && (
                <li className="patterns-data" key={index}>
                  <div className="data-h">
                    <p>{data.name}</p>
                    <span>{data.count}</span>
                  </div>
                </li>
              )
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default PatternsIterable;
