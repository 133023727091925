import { createSlice } from "@reduxjs/toolkit";

const userDataSlice = createSlice({
  name: "userAction",
  initialState: { userData: [], responseRecieved: false, urls: [] },
  reducers: {
    addUserData(state, action) {
      // console.log(action.payload);
      state.userData.push(action.payload);
    },
    setUserData(state, action) {
      state.userData = null;
      state.userData = action.payload;
    },
    resetUserData(state, action) {
      state.userData = null;
      state.responseRecieved = false;
      state.urls = [];
    },
    setResponseRecieved(state, action) {
      state.responseRecieved = action.payload;
    },
    addUrl(state, action) {
      state.urls.push(action.payload);
    },
    removeUrls(state, acion) {
      state.urls = [];
    },
  },
});

export { userDataSlice };
export const {
  setUserData,
  resetUserData,
  setResponseRecieved,
  addUrl,
  removeUrls,
  addUserData,
} = userDataSlice.actions;
