import React, { useState } from "react";
import "./ExpandedParagraph.css";
import { Collapse } from "react-collapse";

const ExpandedParagraph = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="collapsible-paragraph">
      <p className={isExpanded ? "expanded" : "collapsed"}>{content}</p>
      <button className="toggle-button" onClick={toggleExpand}>
        {isExpanded ? "See Less" : "See More"}
      </button>
    </div>
  );
};

export default ExpandedParagraph;
