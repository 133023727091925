import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { animated } from "react-spring";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import UserProfiles from "../UserProfiles/UserProfiles";
import ProcessedChat from "../ProcessedChat/ProcessedChat";
import Patterns from "../Patterns/Patterns";
import Analysis from "../Analysis/Analysis";
import { fetchPattern } from "../../store/slices/UserProfileSlice";
import useUserData from "../../hooks/useUserData";
import Files from "../Files/Files";

const TabPanel = ({ value, index, children }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && (
        <div
          style={{
            padding: "16px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "500",
            marginBottom: "70px",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

const AnimatedTabPanel = animated(TabPanel);

const BeautifulTabComponent = ({ active }) => {
  const dispatch = useDispatch();
  const [mainTabValue, setMainTabValue] = useState(0);
  const [subTabValue, setSubTabValue] = useState(0);
  const responseRecieved = useSelector(
    (state) => state.userData.responseRecieved
  );
  const isProject = useSelector((state) => state.users.isProject);
  const { getData, getAnalysisData } = useUserData();

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  const handleSubTabChange = (event, newValue) => {
    setSubTabValue(newValue);
  };

  useEffect(() => {
    if (responseRecieved && active) {
      dispatch(
        fetchPattern({
          patternsData: {
            data: getData(),
          },
          analysisData: {
            data: getAnalysisData(),
          },
        })
      );
    }
    // eslint-disable-next-line
  }, [responseRecieved]);

  return (
    <div>
      <Tabs value={mainTabValue} onChange={handleMainTabChange}>
        <Tab label="Analysis" disabled={!responseRecieved} />
        <Tab label="Chat" disabled={!responseRecieved} />
        <Tab label="Files" disabled={!responseRecieved} />
      </Tabs>
      <div style={{ position: "relative" }}>
        {mainTabValue === 0 && (
          <div>
            <Tabs value={subTabValue} onChange={handleSubTabChange}>
              <Tab label="User Profiles" disabled={!responseRecieved} />
              <Tab label="Pattern and Trends" disabled={!responseRecieved} />
              <Tab label="Overall Analysis" disabled={!responseRecieved} />
            </Tabs>
            {responseRecieved || isProject ? (
              <>
                <AnimatedTabPanel value={subTabValue} index={0} key={0}>
                  <div>
                    {/* Content for Sub Tab 1 of Main Tab 1 */}
                    <UserProfiles exp={false} />
                  </div>
                </AnimatedTabPanel>
                <AnimatedTabPanel value={subTabValue} index={1} key={1}>
                  <div>
                    {/* Content for Sub Tab 2 of Main Tab 1 */}
                    <Patterns />
                  </div>
                </AnimatedTabPanel>
                <AnimatedTabPanel value={subTabValue} index={2} key={2}>
                  <div>
                    {/* Content for Sub Tab 3 of Main Tab 1 */}
                    <Analysis active={active} />
                  </div>
                </AnimatedTabPanel>
              </>
            ) : (
              <div className="loading-tab">
                <Loader />
                <p>Creating User Profiles</p>
              </div>
            )}
          </div>
        )}
        {mainTabValue === 1 && (
          <div>
            {/* Content for Main Tab 2 */}
            <ProcessedChat />
          </div>
        )}
        {mainTabValue === 2 && (
          <div>
            {/* Content for Main Tab 3 */}
            <Files />
          </div>
        )}
      </div>
    </div>
  );
};

export default BeautifulTabComponent;
