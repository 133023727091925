import React, { useState } from "react";
import "./Contact.css";
import { FaWhatsapp } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";

const Contact = () => {
  const [username, setUsername] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [reason, setReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const url =
    // eslint-disable-next-line
    process.env.REACT_APP_WORKING_ENV == "dev"
      ? process.env.REACT_APP_DJANGO_LOCAL_SERVER
      : process.env.REACT_APP_DJANGO_PROD_SERVER;

  const redirectToWhatsApp = () => {
    const phoneNumber = process.env.REACT_APP_WHATSAPP_MOBILE_NUMBER; // Replace with the recipient's phone number.
    const message = "Hello, this is a test message."; // Replace with your desired message.

    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  const submitHandler = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const data = {
      username: username,
      email: userEmail,
      reason: reason,
    };

    axios
      .post(`${url}/api/send-mail/`, data)
      .then((res) => {
        setUsername("");
        setUserEmail("");
        setReason("");
        setIsLoading(false);
        toast.success("Email Sent Successfully. We'll Contact you shortly", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <div className="contact-main">
      <h2>Lets Get in Touch !</h2>
      <p>
        Have a question or need assistance? Reach out to us via email, phone, or
        the contact form below. We're eager to assist you.
      </p>
      <div className="contact-boxes-main">
        <div className="contact-form-box">
          <form onSubmit={submitHandler}>
            <div className="contact-form-input">
              <p>Full Name</p>
              <input
                type="text"
                placeholder="Enter your Full Name"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="contact-form-input">
              <p>Email</p>
              <input
                type="email"
                placeholder="Enter your Email Address"
                required
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </div>
            <div className="contact-form-input">
              <p>Reason</p>
              <textarea
                placeholder="Describe your Reason...."
                style={{ height: "127px" }}
                required
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              ></textarea>
            </div>
            <button type="submit">
              {isLoading ? (
                <RotatingLines width="15" strokeColor="white" />
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
        <div className="contact-whatsapp-box">
          <h3>Message on Whatsapp</h3>
          <p>
            For priority service feel free to drop us a message on our WhatsApp
          </p>
          <button onClick={redirectToWhatsApp}>
            <FaWhatsapp size={20} /> Message
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
