import React from "react";
import "./UserChat.css";

const UserChat = ({ message }) => {
  return (
    <>
      <div className="user-chat">
        <div className="user-chat-profile">
          <div className="profile-name-chat">You</div>
          <div className="profile-circle"></div>
        </div>
        <p>{message}</p>
      </div>
    </>
  );
};

export default UserChat;
