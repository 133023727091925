import React, { useContext, useEffect, useState } from "react";
import Header from "../Header/Header";
import "./Dashboard.css";
import ReactModal from "react-modal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setProject } from "../../store/slices/UserSlice";
import MyProjects from "../MyProjects/MyProjects";
import { setModal } from "../../store/slices/UserActionSlice";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthProvider";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "700px",
    width: "100%",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    padding: "20px",
    maxHeight: "80%",
    overflow: "auto",
    margin: "auto",
    transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out", // Adjust the animation properties
    zIndex: "1000",
  },
};

export default function Dashboard() {
  const dispatch = useDispatch();
  const [projectName, setProjectName] = useState(null);
  const isModal = useSelector((state) => state.userAction.modalOpen);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const useruid = localStorage.getItem("userId");

  ReactModal.setAppElement("#root");

  function modalClose() {
    if (projectName) {
      dispatch(setProject(projectName));
      navigate("/user-input");
      dispatch(setModal(false));
    } else {
      dispatch(setModal(false));
    }
  }
  useEffect(() => {
    if (!useruid) {
      navigate("/login");
    }
  }, [isModal, currentUser]);
  return (
    <>
      <Header />
      <ToastContainer />
      <div className="main-container">
        <MyProjects />
        <ReactModal
          isOpen={isModal}
          onRequestClose={modalClose}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal-body">
            <div className="modal-heading">
              <p>Create New Project</p>
            </div>
            <div className="modal-input">
              <label htmlFor="project-name">Enter Project Name</label>
              <input
                placeholder="Enter Project Name"
                id="project-name"
                onChange={(e) => setProjectName(e.target.value)}
              />
            </div>
            <div className="modal-button">
              <button onClick={modalClose}>Continue</button>
            </div>
          </div>
        </ReactModal>
      </div>
    </>
  );
}
