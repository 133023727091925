import React, { useEffect, useState } from "react";
import "./Patterns.css";
import { useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import PatternsIterable from "../PatternsIterable/PatternsIterable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
  height: "50%",
  overflow: "auto",
};

const Patterns = () => {
  const patterns = useSelector((state) => state.userProfiles.patterns);
  const [open, setOpen] = useState(false);
  const [selectedPattern, setSelectedPattern] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const handleOpen = (patterns, index) => {
    const lst = ["Themes", "Motivations", "Pain Points", "Needs"];
    setSelectedName(lst[index]);
    setSelectedPattern(patterns);
    setOpen(true);
  };
  const handleClose = () => {
    setSelectedPattern(null);
    setOpen(false);
  };

  useEffect(() => {}, [patterns]);

  return (
    <div className="patterns-trends-main">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="patterns-header">
              <h2
                style={{
                  color: "black",
                  fontSize: "20px",
                  fontWeight: "400",
                }}
              >
                {selectedName}
              </h2>
              <button
                style={{
                  color: "#53545C",
                  border: "none",
                  outline: "none",
                  background: "transparent",
                  fontSize: "13px",
                }}
                onClick={handleClose}
              >
                See less
              </button>
            </div>
            {selectedPattern?.themes?.map((data, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #e8e8e8",
                    marginTop: "10px",
                    color: "#334D6E",
                  }}
                >
                  <p>{data.name}</p>
                  <p>{data.count}</p>
                </div>
              );
            })}
          </Box>
        </Fade>
      </Modal>
      {/* <div className="patterns-trends-box">
        <h2>Sentiment</h2>
      </div> */}
      <div className="patterns-1">
        <div className="patterns-trends-box">
          <PatternsIterable
            heading={"Themes"}
            patterns={patterns}
            iterable={patterns[0]?.themes}
            handleOpen={handleOpen}
            index={0}
          />
        </div>
        <div className="patterns-trends-box">
          <PatternsIterable
            heading={"Motivations"}
            patterns={patterns}
            iterable={patterns[1]?.themes}
            handleOpen={handleOpen}
            index={1}
          />
        </div>
      </div>
      <div className="patterns-1">
        <div className="patterns-trends-box">
          <PatternsIterable
            heading={"Pain Points"}
            patterns={patterns}
            iterable={patterns[2]?.themes}
            handleOpen={handleOpen}
            index={2}
          />
        </div>
        <div className="patterns-trends-box">
          <PatternsIterable
            heading={"Needs"}
            patterns={patterns}
            iterable={patterns[3]?.themes}
            handleOpen={handleOpen}
            index={3}
          />
        </div>
      </div>
    </div>
  );
};

export default Patterns;
