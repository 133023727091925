import { createSlice } from "@reduxjs/toolkit";

const UserActionSlice = createSlice({
  name: "userAction",
  initialState: { currentAction: 1, modalOpen: false },
  reducers: {
    setCurrentAction(state, action) {
      state.currentAction = action.payload;
    },
    setModal(state, action) {
      state.modalOpen = action.payload;
    },
    resetCurrentAction(state, action) {
      state.currentAction = 1;
      state.modalOpen = false;
    },
  },
});

export { UserActionSlice };
export const { setCurrentAction, resetCurrentAction, setModal } =
  UserActionSlice.actions;
