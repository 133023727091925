import React, { useEffect, useState } from "react";
import "./Analysis.css";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ToastContainer } from "react-toastify";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Analysis = ({ active }) => {
  const analysis = useSelector((state) => state.userProfiles.analysis);
  const [actionAbleSteps, setActionAbleSteps] = useState(null);

  function convertNumberedPointsToList(text) {
    const actionableSteps = text
      .split(/\d+\.\s+/)
      .filter((step) => step.trim() !== "");
    return actionableSteps;
  }

  // eslint-disable-next-line
  useEffect(() => {
    if (analysis) {
      setActionAbleSteps(convertNumberedPointsToList(analysis[1]));
    }
    // eslint-disable-next-line
  }, [analysis]);
  return (
    <div className="analysis-main">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="analysis-box">
        <div className="analysis-data">
          <h2>Overall Analysis</h2>
          <span>
            {!analysis ? (
              <Box sx={{ width: "100%" }}>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </Box>
            ) : (
              <Markdown remarkPlugins={[remarkGfm]}>{analysis[0]}</Markdown>
            )}
          </span>
        </div>
        <div className="analysis-data">
          <h2>Next Actionable Steps</h2>
          {!analysis ? (
            <Box sx={{ width: "100%", marginBottom: "0" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
          ) : (
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {actionAbleSteps?.map(
                    (row, index) =>
                      // eslint-disable-next-line
                      index != 0 && (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row" sx={{
                            fontWeight:400,
                            color:"#334d6e",
                            fontSize:"16px"
                          }}>
                            {index}
                          </TableCell>
                          <TableCell align="left">{row}</TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default Analysis;
