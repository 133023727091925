import { useDispatch, useSelector } from "react-redux";
// import { addUrl } from "../store/slices/UserDataSlice";
import { getAuth } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import {
  resetUserProfile,
  setIsLoading,
} from "../store/slices/UserProfileSlice";
import { toast } from "react-toastify";
import { resetChats } from "../store/slices/ChatSlice";
import { resetCurrentAction } from "../store/slices/UserActionSlice";
import { resetProjectName } from "../store/slices/UserSlice";
import { removeUrls, resetUserData } from "../store/slices/UserDataSlice";

const useUserData = () => {
  const dispatch = useDispatch();
  const userProfiles = useSelector((state) => state.userProfiles.userProfiles);
  const userData = useSelector((state) => state.userData.userData);
  const userLimits = useSelector((state) => state.users.userData);
  const project_id = useSelector((state) => state.users.projectId);
  const projectName = useSelector((state) => state.users.projectName);
  const patterns = useSelector((state) => state.userProfiles.patterns);
  const analysis = useSelector((state) => state.userProfiles.analysis);

  function createBodyData() {
    const lst = [];
    userData.forEach((element) => {
      const string = { name: element.name, val: element.value };
      lst.push(string);
    });
    return lst;
  }

  function getData() {
    const lst = [];
    const data = { themes: [], motivation: [], painpoint: [], need: [] };
    userProfiles.forEach((element) => {
      data.themes.push(element.response.theme.toString());
      data.motivation.push(element.response.motivation.toString());
      data.painpoint.push(element.response.painpoint.toString());
      data.need.push(element.response.need.toString());
    });
    data.themes = data.themes.toString();
    data.motivation = data.motivation.toString();
    data.painpoint = data.painpoint.toString();
    data.need = data.need.toString();
    lst.push({ themes: data.themes });
    lst.push({ motivation: data.motivation });
    lst.push({ painpoint: data.painpoint });
    lst.push({ need: data.need });
    return lst;
  }

  function getAnalysisData() {
    const lst = [];
    userProfiles.forEach((element) => {
      lst.push({ insight: element.response.insight.toString() });
    });
    return lst;
  }

  async function updateUserStatus() {
    const auth = getAuth();
    const user_id = auth.currentUser.uid;
    const userRef = doc(db, "users", user_id);
    await updateDoc(userRef, {
      number_of_projects: userLimits.number_of_projects - 1,
      number_of_interviews: userLimits.number_of_interviews - userData.length,
    });
  }

  const setUserProject = async () => {
    try {
      const Auth = getAuth();
      const user_id = Auth.currentUser.uid;
      const userRef = doc(db, "projects", project_id);

      const newDoc = {
        user_id: user_id,
        username: Auth.currentUser.displayName,
        user_profiles: userProfiles,
        user_data: userData,
        project_name: projectName,
        patterns: patterns,
        analysis: analysis,
      };
      await updateDoc(userRef, newDoc);
      dispatch(setIsLoading(false));
      console.log("project saved");
    } catch (error) {
      dispatch(setIsLoading(false));
      console.error("Error adding document:", error);
      toast.error("Error adding document", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const resetGlobalStates = () => {
    dispatch(resetChats());
    dispatch(resetCurrentAction());
    dispatch(resetProjectName());
    dispatch(resetUserData());
    dispatch(resetUserProfile());
    dispatch(removeUrls());
    // console.log("All states reset");
  };
  return {
    getData,
    getAnalysisData,
    createBodyData,
    updateUserStatus,
    setUserProject,
    resetGlobalStates,
  };
};

export default useUserData;
