import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl =
  // eslint-disable-next-line
  process.env.REACT_APP_WORKING_ENV == "dev"
    ? process.env.REACT_APP_DJANGO_LOCAL_SERVER
    : process.env.REACT_APP_DJANGO_PROD_SERVER;

export const fetchPattern = createAsyncThunk(
  "fetch/patterns",
  async ({ patternsData, analysisData }, { dispatch }) => {
    console.log("patterns body data", patternsData);
    const url = `${baseUrl}/api/get-patterns/`;
    try {
      const response = await axios.post(url, patternsData);
      console.log("patterns response", response);
      dispatch(fetchAnalysis(analysisData));
      return response.data.response;
    } catch (error) {
      return error.message;
    }
  }
);

export const fetchAnalysis = createAsyncThunk(
  "fetch/Analysis",
  async (data) => {
    const url = `${baseUrl}/api/overall-analysis/`;
    try {
      const response = await axios.post(url, data);
      return response.data.response;
    } catch (error) {
      return error.message;
    }
  }
);

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: {
    userProfiles: [],
    isLoading: false,
    patterns: null,
    analysis: null,
    files: [],
    isComplete: false,
  },
  reducers: {
    addUserProfile(state, action) {
      state.userProfiles.push(action.payload);
    },
    setIsComplete(state, action) {
      state.isComplete = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setPattern(state, action) {
      state.patterns = action.payload;
    },
    setAnalysis(state, action) {
      state.analysis = action.payload;
    },
    addFile(state, action) {
      state.files.push(action.payload);
    },
    sortProfiles(state, action) {
      state.userProfiles.sort((a, b) => a.userId - b.userId);
    },
    resetUserProfile(state, action) {
      state.userProfiles = [];
      state.isLoading = false;
      state.patterns = null;
      state.analysis = null;
      state.files = [];
      state.isComplete = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPattern.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchPattern.fulfilled, (state, action) => {
        state.patterns = action.payload;
      })
      .addCase(fetchPattern.rejected, (state, action) => {
        console.log(action.error.message);
      })
      .addCase(fetchAnalysis.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchAnalysis.fulfilled, (state, action) => {
        state.analysis = action.payload;
        state.isComplete = true;
        // state.isLoading = false;
      })
      .addCase(fetchAnalysis.rejected, (state, action) => {
        state.isLoading = false;
        console.log(action.error.message);
      });
  },
});

export { userProfileSlice };
export const {
  addUserProfile,
  resetUserProfile,
  setIsLoading,
  setPattern,
  setAnalysis,
  addFile,
  sortProfiles,
} = userProfileSlice.actions;
