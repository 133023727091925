import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./ProgressLoader.css";

const ProgressLoader = ({ value }) => {
  return (
    <div className="progress-loader-main">
      <div className="progress-loader-inner">
        <CircularProgress
          variant="determinate"
          value={value}
          style={{ margin: 0 }}
        />
        <p>{`${value}%`}</p>
      </div>
    </div>
  );
};

export default ProgressLoader;
