import React, { useState, useEffect } from "react";
import "./NewTabComponent.css";
import { BsBox } from "react-icons/bs";
import { AiOutlineMessage } from "react-icons/ai";
import { FiFolder } from "react-icons/fi";
import SubTabComponent from "../SubTabComponent/SubTabComponent";
import { useDispatch, useSelector } from "react-redux";
import useUserData from "../../hooks/useUserData";
import { fetchPattern } from "../../store/slices/UserProfileSlice";
import ProcessedChat from "../ProcessedChat/ProcessedChat";
import Files from "../Files/Files";

const NewTabComponent = ({ active }) => {
  const dispatch = useDispatch();
  const upperTabs = [
    { name: "Analysis", icon: <BsBox size={19} /> },
    { name: "Chat", icon: <AiOutlineMessage size={20} /> },
    { name: "files", icon: <FiFolder size={20} /> },
  ];
  const responseRecieved = useSelector(
    (state) => state.userData.responseRecieved
  );
  const isProject = useSelector((state) => state.users.isProject);
  const { getData, getAnalysisData } = useUserData();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (responseRecieved && active) {
      dispatch(
        fetchPattern({
          patternsData: {
            data: getData(),
          },
          analysisData: {
            data: getAnalysisData(),
          },
        })
      );
    }
    // eslint-disable-next-line
  }, [responseRecieved]);

  return (
    <div className="tab-component-main">
      <div className="upper-tabs">
        {upperTabs.map((tab, index) => {
          return (
            <div
              className="upper-tab"
              key={index}
              onClick={() => {
                if (responseRecieved) {
                  setCurrentTab(index);
                }
              }}
            >
              <div
                className="upper-tab-body"
                style={{ color: index === currentTab ? "#6d4697" : "#53545C" }}
              >
                {tab.icon}
                {tab.name}
              </div>
              <div
                className="upper-tab-bottom"
                style={{ width: index === currentTab ? "100%" : "0" }}
              ></div>
            </div>
          );
        })}
      </div>
      <div className="lower-tabs">
        {currentTab === 0 && <SubTabComponent active={active} />}
        {currentTab === 1 && <ProcessedChat />}
        {currentTab === 2 && <Files />}
      </div>
    </div>
  );
};

export default NewTabComponent;
