import React from "react";
import "./TermsCondition.css";
import { useNavigate } from "react-router-dom";

const TermsCondition = () => {
  const navigate = useNavigate();
  return (
    <div className="terms-conditions-main">
      <div className="terms-conditions-box">
        <h2>Insightio AI Terms and Conditions</h2>
        <h3>Last Updated: 1st November, 2023</h3>
        <p>
          Please read these Terms and Conditions ("Terms," "Terms and
          Conditions") carefully before using the Insightio AI tool ("the Tool")
          provided by [Your Company Name] ("We," "Us," or "Our"). By using the
          Insightio AI Tool, you acknowledge that you have read, understood, and
          agreed to these Terms and Conditions.
        </p>
        <h3>1. Acceptance of Terms</h3>
        <p>
          By accessing or using the Tool, you agree to be bound by these Terms
          and Conditions. If you do not agree to these Terms, please refrain
          from using the Tool. These Terms and Conditions are subject to change,
          and it is your responsibility to review them periodically to stay
          informed of any updates.
        </p>
        <h3>2. Use of the Tool</h3>
        <p>
          - The Tool, "Insightio AI," is designed to analyze user-provided data
          in video, audio, and text formats using OpenAI APIs. You may use the
          Tool for legitimate and lawful purposes only. - You are solely
          responsible for the data you upload to the Tool. We do not claim
          ownership of your data, but by using the Tool, you grant us a limited
          license to use, reproduce, modify, and analyze your data solely for
          the purpose of providing you with the Tool's services. - You agree not
          to use the Tool to engage in any unlawful, unethical, or harmful
          activities, including, but not limited to, uploading copyrighted or
          inappropriate content.
        </p>
        <h3>3. Privacy and Data Security</h3>
        <p>
          - We take the privacy and security of your data seriously. - We employ
          reasonable security measures to protect your data and do not utilize
          it for marketing purposes or selling to third part
        </p>
        <h3>4. OpenAI APIs</h3>
        <p>
          - Insightio AI utilizes OpenAI APIs to analyze user data. You must
          comply with OpenAI's terms of service in addition to these Terms and
          Conditions. - We are not responsible for any interruptions, changes,
          or discontinuations in the availability or functionality of the OpenAI
          APIs.
        </p>
        <h3>5. User Conduct</h3>
        <p>
          - You agree not to engage in any conduct that disrupts, interferes
          with, or hinders the proper operation of the Tool or any third-party
          users. - You shall not attempt to reverse engineer, decompile, or
          disassemble the Tool or any part thereof.
        </p>
        <h3>6. Termination</h3>
        <p>
          - We reserve the right to terminate or suspend your access to the Tool
          at our discretion if you breach these Terms and Conditions. - Upon
          termination, you must cease using the Tool, and any licenses granted
          to you will be revoked.
        </p>
        <h3>7. Limitation of Liability</h3>
        <p>
          - We shall not be liable for any direct, indirect, incidental,
          special, or consequential damages arising out of or in any way
          connected with your use of the Tool.
        </p>
        <h3>8. Governing Law</h3>
        <p>
          - These Terms and Conditions shall be governed by and construed in
          accordance with the laws
        </p>
        <h3>9. Third-Party Tools for User Experience Improvement</h3>
        <p>
          - We utilize third-party screen recording tools, including but not
          limited to Mixpanel, Clarity, Hotjar, and Google Analytics, to enhance
          the user experience on our platform. These tools help us gather
          insights into user behavior, preferences, and interactions, allowing
          us to make informed decisions for platform optimization. - By using
          the Insightio AI Tool, you acknowledge and consent to the use of these
          third-party tools for the purpose of improving the overall user
          experience. Please refer to the respective privacy policies of these
          tools for more information on how they handle and process data. We do
          not assume responsibility for the actions or policies of these
          third-party tools.
        </p>
        <h3>
          10. Non-Utilization of User Audio and Video Files for Marketing or
          Third-Party Sale
        </h3>
        <p>
          - We want to reassure our users that Insightio AI does not, under any
          circumstances, utilize user-provided audio and video files for
          marketing purposes or sell them to any third-party organization. Your
          data remains confidential and is used solely for the purpose of
          providing you with the analysis and insights generated by the Tool. -
          We are committed to upholding the privacy and security of your data.
          For more information on how we handle your data, please refer to our
          Privacy Policy.
        </p>
        <h3>11. Contact Information</h3>
        <p>
          If you have any questions or concerns about these Terms and
          Conditions, please contact us at romman@insightio.ai By using the
          Insightio AI Tool, you acknowledge that you have read, understood, and
          agreed to these Terms and Conditions.
        </p>
        <button
          onClick={() => {
            navigate("/signup");
          }}
        >
          Go back to Signup
        </button>
      </div>
    </div>
  );
};

export default TermsCondition;
