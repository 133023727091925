import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { BiFolder } from "react-icons/bi";
import "./UploadedFile.css";
import { RotatingLines } from "react-loader-spinner";
import { v4 as uuid } from "uuid";
import axios from "axios";
import ProgressLoader from "../ProgressLoader/ProgressLoader";
import { useDispatch } from "react-redux";
import { addUrl } from "../../store/slices/UserDataSlice";

const UploadedFile = ({ file, deletefile, index, isSubmitted }) => {
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [fileLink, setFileLink] = useState(null);
  const [progress, setProgress] = useState(0);

  async function uploadFileToSignedUrl(
    signedUrl,
    file,
    contentType,
    onProgress,
    onComplete
  ) {
    axios
      .put(signedUrl, file, {
        onUploadProgress: (progressEvent) => {
          // Calculate the upload progress percentage
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          onProgress(parseInt(progress));
        },
        headers: {
          "Content-Type": contentType,
        },
      })
      .then((response) => {
        onComplete(response);
      })
      .catch((err) => {
        console.error(err.response);
      });
  }

  useEffect(() => {
    if (isSubmitted) {
      const url =
        process.env.REACT_APP_WORKING_ENV === "dev"
          ? process.env.REACT_APP_NODE_SERVER_DEV_BASE_URL
          : process.env.REACT_APP_NODE_SERVER_PROD_BASE_URL;

      const extension = file.name.split(".")[1];

      const key = uuid();
      setIsUploading(true);
      axios
        .post(`${url}/api/signed-url`, {
          key: `${key}.${extension}`,
          content_type: file.type,
        })
        .then((res) => {
          uploadFileToSignedUrl(
            res.data.signedUrl,
            file,
            file.type,
            setProgress,
            () => {
              // setFileLink(res.data.fileLink);
              dispatch(
                addUrl({
                  url: res.data.fileLink,
                  userId: index + 1,
                })
              );
              setIsUploading(false);
            }
          );
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [isSubmitted]);
  return (
    <div className="list-items-h">
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <BiFolder size={25} />
        <li className="truncate">{file.name}</li>
      </div>
      {isSubmitted ? (
        <ProgressLoader value={progress} />
      ) : (
        <button onClick={() => deletefile(index)}>
          <MdDelete size={20} color="red" />
        </button>
      )}
    </div>
  );
};

export default UploadedFile;
