import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import {
  getAdditionalUserInfo,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { auth, provider } from "../../firebase";
import { FcGoogle } from "react-icons/fc";
import { ToastContainer, toast } from "react-toastify";
import { setDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = React.useState(false);
  const navigation = useNavigate();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const createUserDocument = async (userCredentials) => {
    // const userRef = doc(db, "user", userCredentials.user.uid);
    // // const document = await userRef.get();
    let data;
    await setDoc(doc(db, "users", userCredentials.user.uid), {
      uid: userCredentials.user.uid,
      username: userCredentials.user.displayName,
      email: userCredentials.user.email,
      organization: "",
      role: userCredentials.user.providerData[0].providerId,
      member_ship_status: "free",
      photoUrl: userCredentials.user.photoURL,
      number_of_projects: 2,
      number_of_interviews: 6,
      number_of_chats: 3,
    })
      .then((res) => {
        localStorage.setItem("userId", userCredentials.user.uid);
        navigation("/");
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
    // onSnapshot(userRef, async (snap) => {
    //   if (snap.exists()) {
    //     await setDoc(doc(db, "users", userCredentials.user.uid), {
    //       uid: userCredentials.user.uid,
    //       username: userCredentials.user.displayName,
    //       email: userCredentials.user.email,
    //       organization: "",
    //       role: userCredentials.user.providerData[0].providerId,
    //       member_ship_status: "free",
    //       photoUrl: userCredentials.user.photoURL,
    //     })
    //       .then((res) => {
    //         localStorage.setItem("userId", userCredentials.user.uid);
    //         navigation("/");
    //       })
    //       .catch((err) => {
    //         toast.error(err.message, {
    //           position: "top-right",
    //           autoClose: 3000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //           theme: "colored",
    //         });
    //       });
    //   } else {
    //     await setDoc(doc(db, "users", userCredentials.user.uid), {
    //       uid: userCredentials.user.uid,
    //       username: userCredentials.user.displayName,
    //       email: userCredentials.user.email,
    //       organization: "",
    //       role: userCredentials.user.providerData[0].providerId,
    //       member_ship_status: "free",
    //       photoUrl: userCredentials.user.photoURL,
    //       number_of_projects: 2,
    //       number_of_interviews: 6,
    //       number_of_chats: 3,
    //     })
    //       .then((res) => {
    //         localStorage.setItem("userId", userCredentials.user.uid);
    //         navigation("/");
    //       })
    //       .catch((err) => {
    //         toast.error(err.message, {
    //           position: "top-right",
    //           autoClose: 3000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //           theme: "colored",
    //         });
    //       });
    //   }
    // });
    // console.log(data);
  };

  const googleSigninHandler = () => {
    handleOpen();
    signInWithPopup(auth, provider)
      .then(async (userCredentials) => {
        const details = getAdditionalUserInfo(userCredentials);
        if (details.isNewUser) {
          createUserDocument(userCredentials);
        } else {
          localStorage.setItem("userId", userCredentials.user.uid);
          navigation("/");
          handleClose();
        }
      })
      .catch((err) => {
        handleClose();
        toast.error(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    handleOpen();
    if (email && password) {
      signInWithEmailAndPassword(auth, email, password)
        .then((user) => {
          if (user.user.emailVerified) {
            handleClose();
            localStorage.setItem("userId", user.user.uid);
            navigation("/");
          } else {
            handleClose();
            toast.warning(
              "Account not verified, Kindly check your email to verify your account",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          }
        })
        .catch((error) => {
          handleClose();
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  return (
    <>
      <div className="login-container">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Same as */}
        <ToastContainer />
        <div className="login-box">
          <form onSubmit={onSubmitHandler}>
            <div className="login-heading">
              <h2>Welcome Back!</h2>
              <p>Login to get started</p>
            </div>
            <div className="login-input-fields">
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                type="email"
                required
                style={{ marginBottom: "15px" }}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Password"
                variant="outlined"
                fullWidth
                type="password"
                required
                style={{ marginBottom: "15px" }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="remember-me-box">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox {...label} />
                <p style={{ color: "#989898", margin: "0" }}>Remember me</p>
              </div>
              <div className="forgot-password-login">
                <p onClick={() => navigation("/reset-password")}>
                  Forgot Password
                </p>
              </div>
            </div>
            <div className="login-button">
              <button type="submit">Login</button>
            </div>
          </form>
          <div className="or-line">
            <div></div>
            <span>Or</span>
            <div></div>
          </div>
          <div className="google-login-button">
            <button onClick={googleSigninHandler}>
              <FcGoogle size={30} /> Login with Google
            </button>
          </div>
          <div className="navigate-to-signup">
            <p>
              Don't have an account?{" "}
              <span onClick={() => navigation("/signup")}>Create one here</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
