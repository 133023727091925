import React, { useEffect, useState } from "react";
import "./MyProjects.css";
import { BiPlus } from "react-icons/bi";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../store/slices/UserActionSlice";
import { ToastContainer, toast } from "react-toastify";
import { FaFolder } from "react-icons/fa";
import { FiUser } from "react-icons/fi";

const MyProjects = () => {
  const [myProjects, setMyProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.users.userData);

  function openModal() {
    dispatch(setModal(true));
  }

  const fetchProjects = async (userId) => {
    setIsLoading(true);
    const q = query(collection(db, "projects"), where("user_id", "==", userId));
    const projects = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      projects.push([doc.id, doc.data()]);
    });
    setMyProjects(projects);
    setIsLoading(false);
  };
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    fetchProjects(userId);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="my-projects-main-container">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="create-new-project-container">
        <div
          className="create-new-project-button"
          onClick={() => {
            if (userData?.number_of_projects > 0) {
              openModal();
            } else {
              toast.error("Projects Limit Reached", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          }}
        >
          <p>Create a New Project</p>
          <BiPlus size={25} color="#6D4697" />
        </div>
      </div>
      <div className="my-projects-container">
        <div className="heading">
          <h3>Projects</h3>
        </div>
        <div className="project-boxes-h">
          {isLoading ? (
            <Loader />
          ) : myProjects.length > 0 ? (
            myProjects.map((ele, index) => {
              return (
                <div
                  className="project-box-h"
                  key={index}
                  onClick={() => navigate(`/project/${ele[0]}`)}
                >
                  <div className="project-main-h">
                    <FaFolder color="#6D4697" size={90} />
                    <p style={{ fontWeight: "bold" }}>{ele[1]?.project_name}</p>
                    <div className="user-project-interviews-count">
                      <FiUser size={15} color="#53545C" />
                      <span>{ele[1]?.user_profiles?.length} Profiles</span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-projects-container">
              <h2>No Projects</h2>
              <div
                className="create-new-project-button"
                onClick={() => {
                  if (userData?.number_of_projects > 0) {
                    openModal();
                  } else {
                    toast.error("Projects Limit Reached", {
                      position: "top-right",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    });
                  }
                }}
              >
                <p>Create a New Project</p>
                <BiPlus size={25} color="#6D4697" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyProjects;
