import React, { useEffect } from "react";
import "./UserProfiles.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { ExpandMoreOutlined } from "@mui/icons-material";
import { sortProfiles } from "../../store/slices/UserProfileSlice";
import Iterable from "../Iterable/Iterable";
import { BiSolidUpArrow } from "react-icons/bi";

const UserProfiles = ({ exp }) => {
  const dispatch = useDispatch();
  const userProfiles = useSelector((state) => state.userProfiles.userProfiles);

  useEffect(() => {
    if (userProfiles) {
      dispatch(sortProfiles());
    }
  }, [userProfiles]);
  return (
    <div className="user-profiles-container">
      {userProfiles?.map((ele, index) => {
        return (
          <div className="user-profile-box" key={index}>
            <Accordion
              defaultExpanded={exp}
              sx={{
                background: "white",
                boxShadow: "none",
                border: "none",
                borderRadius: "18px",
                padding: "5px 0",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  style={{ fontFamily: "Poppins", fontWeight: "500" }}
                >{`User ${ele?.userId} Profile`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="user-profile-generic">
                  <div className="user-profile-profile box">
                    <h2>Profile</h2>
                    <p>{ele?.response.summary}</p>
                  </div>
                  <div className="user-profile-sentiment box">
                    <h2>Sentiment</h2>
                    <p>This represents the overall sentiment of the user</p>
                    <div className="sentiment-bar">
                      <div
                        className="sentiment-arrow"
                        style={{
                          left: `${ele?.response?.sentiment * 10 - 5}%`,
                        }}
                      >
                        <BiSolidUpArrow />
                      </div>
                    </div>
                    <div className="sentiment-result">
                      <p
                        style={{
                          visibility:
                            ele?.response?.sentiment <= 4 && "visible",
                        }}
                      >
                        Negative
                      </p>
                      <p
                        style={{
                          visibility:
                            ele?.response?.sentiment > 4 &&
                            ele?.response?.sentiment <= 7 &&
                            "visible",
                        }}
                      >
                        Neutral
                      </p>
                      <p
                        style={{
                          visibility:
                            ele?.response?.sentiment >= 8 && "visible",
                        }}
                      >
                        Positive
                      </p>
                    </div>
                  </div>
                </div>
                <div className="user-profile-generic">
                  <div className="user-profile-themes box equal-box">
                    <Iterable
                      heading={"Themes"}
                      iterable={ele?.response?.theme}
                    />
                  </div>
                  <div className="user-profile-motivations box equal-box">
                    <Iterable
                      heading={"Motivations"}
                      iterable={ele?.response?.motivation}
                    />
                  </div>
                </div>
                <div className="user-profile-generic">
                  <div className="user-profile-painpoints box equal-box">
                    <Iterable
                      heading={"Pain Points"}
                      iterable={ele?.response?.painpoint}
                    />
                  </div>
                  <div className="user-profile-needs box equal-box">
                    <Iterable
                      heading={"Needs"}
                      iterable={ele?.response?.need}
                    />
                  </div>
                </div>
                <div className="user-profile-generic">
                  <div className="user-profile-insights box">
                    <Iterable
                      heading={"Insights"}
                      iterable={ele?.response?.insight}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
};

export default UserProfiles;
