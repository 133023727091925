import React from "react";

const Iterable = ({ heading, iterable, openModal }) => {
  return (
    <>
      <div className="user-profiles-header">
        <h2>{heading}</h2>
        {/* {iterable instanceof Array && iterable?.length > 5 && (
          <button onClick={openModal}>See all</button>
        )} */}
      </div>
      <ul className="data-list">
        {iterable instanceof Array &&
          iterable?.map((data, index) => {
            return (
              <div className="data-list-item" key={index}>
                <li
                  key={index}
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "200",
                  }}
                >
                  {data}
                </li>
              </div>
            );
          })}
      </ul>
    </>
  );
};

export default Iterable;
