import React, { useEffect, useState } from "react";
import "./Project.css";
import { useNavigate, useParams } from "react-router-dom";
import { deleteDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch } from "react-redux";
import {
  addUserProfile,
  resetUserProfile,
  setAnalysis,
  setIsLoading,
  setPattern,
} from "../../store/slices/UserProfileSlice";
import { FaArrowLeft } from "react-icons/fa";
// import BeautifulTabComponent from "../TabComponent/TabComponent";
import {
  resetUserData,
  setResponseRecieved,
  setUserData,
} from "../../store/slices/UserDataSlice";
import {
  resetProjectName,
  setIsProject,
  setProjectId,
} from "../../store/slices/UserSlice";
import { addChat, resetChats } from "../../store/slices/ChatSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import NewTabComponent from "../NewTabComponent/NewTabComponent";
import DeleteIconSrc from "../../assets/icons/delete.png";
import { RotatingLines } from "react-loader-spinner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxWidth: "597px",
  width: "100%",
  borderRadius: "8px",
};

const Project = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isDeleting, setIsDeleting] = useState(false);

  async function deleteProject() {
    setIsDeleting(true);
    handleOpen();
    await deleteDoc(doc(db, "projects", projectId)).then((res) => {
      handleClose();
      setTimeout(() => {
        dispatch(resetUserData());
        dispatch(resetUserProfile());
        dispatch(resetProjectName());
        dispatch(resetChats());
        handleClose();
        setIsDeleting(false);
        navigate("/dashboard");
      }, 1000);
    });
  }

  async function fetchProduct() {
    const docRef = doc(db, "projects", projectId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setProject(docSnap.data());
      dispatch(setUserData(docSnap.data().user_data));
      docSnap.data().user_profiles.forEach((profile) => {
        dispatch(addUserProfile(profile));
      });
      docSnap.data().chats.forEach((chat) => {
        dispatch(addChat(chat));
      });
      dispatch(setPattern(docSnap.data().patterns));
      dispatch(setAnalysis(docSnap.data().analysis));
    } else {
      console.log("No such document!");
    }
  }
  useEffect(() => {
    dispatch(setResponseRecieved(true));
    dispatch(setIsLoading(false));
    dispatch(setIsProject(true));
    dispatch(setProjectId(projectId));
    if (projectId) {
      fetchProduct();
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="single-project-main">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="project-top-buttons">
          <div style={{ display: "flex", gap: "20px" }}>
            <button
              className="project-back-button"
              onClick={() => {
                dispatch(resetUserData());
                dispatch(resetUserProfile());
                dispatch(resetProjectName());
                dispatch(resetChats());
                navigate("/dashboard");
              }}
            >
              <FaArrowLeft size={20} />
            </button>
            <div className="single-project-name">
              <input
                type="text"
                defaultValue={project?.project_name}
                id="project"
              />
            </div>
          </div>
          <Tooltip title="Delete">
            <IconButton onClick={handleOpen}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="delete-modal-info">
                <img src={DeleteIconSrc} alt="delete-icon" />
                <p>
                  Are you sure you want to delete this project? Deleted projects
                  can not be recovered.{" "}
                </p>
              </div>
              <div className="modal-button-group">
                <button className="cancel-button" onClick={handleClose}>
                  Cancel
                </button>
                <button className="delete-button" onClick={deleteProject}>
                  {isDeleting ? (
                    <RotatingLines
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="1"
                      width="20"
                      visible={true}
                    />
                  ) : (
                    "Delete"
                  )}
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>

        <div className="single-project-user-data">
          <div className="single-user-data">
            {/* <BeautifulTabComponent active={false} /> */}
            <NewTabComponent />
          </div>
        </div>
      </div>
    </>
  );
};

export default Project;
