import React from "react";
import "./AiChat.css";
import Markdown from "react-markdown";

const AiChat = ({ message }) => {
  return (
    <>
      <div className="ai-chat">
        <div className="ai-profile">
          <div className="profile-circle"></div>
          <div className="profile-name-chat">Insightio Ai</div>
        </div>
        <span><Markdown>{message}</Markdown></span>
      </div>
    </>
  );
};

export default AiChat;
