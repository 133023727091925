import React from "react";
import "./Files.css";
import { useSelector } from "react-redux";
import ExpandedParagraph from "../ExpandedParagraph/ExpandedParagraph";

const Files = () => {
  const userData = useSelector((state) => state.userData.userData);
  return (
    <div className="files-main">
      <h3>Text</h3>
      <div className="files-textual-data">
        {userData?.map((data, index) => {
          return (
            <div key={index} className="files-textual-box">
              <span style={{ textTransform: "capitalize" }}>
                {`${data?.name}`}
                {": "}
              </span>
              <ExpandedParagraph content={data?.value} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Files;
