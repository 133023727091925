import React, { useEffect, useState } from "react";
import "./SubTabComponent.css";
import { LuUser } from "react-icons/lu";
import { FiTrendingUp } from "react-icons/fi";
import { BsLightningCharge } from "react-icons/bs";
import UserProfiles from "../UserProfiles/UserProfiles";
import Patterns from "../Patterns/Patterns";
import Analysis from "../Analysis/Analysis";
import { useSelector } from "react-redux";
import InsightioLoader from "../InsightioLoader/InsightioLoader";
import { RotatingLines } from "react-loader-spinner";

const SubTabComponent = ({ active }) => {
  const lowerTabs = [
    { name: "User Profiles", icon: <LuUser size={19} /> },
    { name: "Patterns and Trends", icon: <FiTrendingUp size={20} /> },
    { name: "Overall Analysis", icon: <BsLightningCharge size={20} /> },
  ];
  const [patternsTabLoading, setPatternsTabLoading] = useState(true);
  const [analysisTabLoading, setAnalysisTabLoading] = useState(true);
  const [currentSubTab, setCurrentSubTab] = useState(0);
  const responseRecieved = useSelector(
    (state) => state.userData.responseRecieved
  );
  const patterns = useSelector((state) => state.userProfiles.patterns);
  const analysis = useSelector((state) => state.userProfiles.analysis);
  const isProject = useSelector((state) => state.users.isProject);

  useEffect(() => {
    if (patterns) {
      setPatternsTabLoading(false);
    }
    if (analysis) {
      setAnalysisTabLoading(false);
    }
  }, [patterns, analysis]);
  return (
    <div className="sub-tab-main">
      <div className="sub-tab-body-main">
        {lowerTabs.map((tab, index) => {
          const isPatternsLoading = index === 1 && patternsTabLoading;
          const isAnalysisLoading = index === 2 && analysisTabLoading;

          return (
            <div
              className="sub-tab-box"
              key={index}
              onClick={() => {
                if (index === 0) {
                  setCurrentSubTab(index);
                }
                if (index === 1 && patterns) {
                  setCurrentSubTab(index);
                }
                if (index === 2 && analysis) {
                  setCurrentSubTab(index);
                }
              }}
              style={{
                background: index === currentSubTab ? "#6D4697" : "white",
              }}
            >
              <button>
                <div
                  className="sub-tab-body"
                  style={{ color: index === currentSubTab ? "white" : "black" }}
                >
                  {(index === 0 ||
                    (index === 1 && patterns) ||
                    (index === 2 && analysis)) &&
                    tab.icon}

                  {((index === 1 && !patterns) ||
                    (index === 2 && !analysis)) && (
                    <RotatingLines
                      strokeColor="black"
                      strokeWidth="5"
                      animationDuration="1"
                      width="20"
                      visible={true}
                    />
                  )}
                  <p>{tab.name}</p>
                </div>
              </button>
            </div>
          );
        })}
      </div>
      <div className="sub-tabs-data">
        {responseRecieved || isProject ? (
          <>
            {currentSubTab === 0 && <UserProfiles exp={false} />}
            {currentSubTab === 1 && <Patterns />}
            {currentSubTab === 2 && <Analysis active={active} />}
          </>
        ) : (
          <div className="insightio-loader">
            <div className="loader-body">
              <InsightioLoader />
              <p>Insightio Ai is analyzing your data</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubTabComponent;
