import React, { useState } from "react";
import "./SignUp.css";
import { useNavigate } from "react-router-dom";
import { auth, provider } from "../../firebase";
import {
  signInWithPopup,
  createUserWithEmailAndPassword,
  updateProfile,
  setPersistence,
  browserSessionPersistence,
  getAdditionalUserInfo,
  sendEmailVerification,
} from "firebase/auth";
import { db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { FcGoogle } from "react-icons/fc";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { ToastContainer, toast } from "react-toastify";

export default function SignUp() {
  const navigation = useNavigate();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [organization, setOrganization] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = React.useState(false);
  const [role, setRole] = React.useState("");

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const setUserProfile = async (id) => {
    try {
      const userRef = doc(db, "users", id);
      const newDoc = {
        uid: id,
        username: username,
        email: email,
        organization: organization,
        role: role,
        member_ship_status: "free",
        photoUrl: "",
        number_of_projects: 6,
        number_of_interviews: 6,
        number_of_chats: 9,
      };
      await setDoc(userRef, newDoc);
    } catch (error) {
      console.error("Error adding document:", error);
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const googleSignupHandler = async () => {
    setPersistence(auth, browserSessionPersistence).then(async () => {
      try {
        handleOpen();
        await signInWithPopup(auth, provider).then(async (userCredentials) => {
          const details = getAdditionalUserInfo(userCredentials);
          if (details.isNewUser) {
            setUserProfile(userCredentials.user.uid);
          } else {
            localStorage.setItem("userId", userCredentials.user.uid);
            navigation("/");
            handleClose();
          }
        });
      } catch (err) {
        handleClose();
        console.log("err");
        toast.error(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    handleOpen();
    if (email && username && password) {
      setError(null);
      await createUserWithEmailAndPassword(auth, email, password)
        .then(async (user) => {
          await setUserProfile(user.user.uid);
          sendEmailVerification(user.user).then(async () => {
            toast.success(
              "Verification Email has been sent kindly verify your account",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              }
            );
            await updateProfile(user.user, { displayName: username }).then(
              () => {
                navigation("/login");
              }
            );
          });
        })
        .catch((error) => {
          handleClose();
          console.log(error.message);
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  };

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  return (
    <>
      <div className="signup-container">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="signup-main-container">
          <h2>Welcome To Insightio</h2>
          <p>Enter your details to create your account</p>
          <form onSubmit={onSubmitHandler}>
            <TextField
              // id="outlined-basic"
              label="Username"
              variant="outlined"
              fullWidth
              type="text"
              required
              style={{ marginBottom: "15px" }}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              // id="outlined-basic"
              label="Email"
              variant="outlined"
              fullWidth
              type="email"
              required
              style={{ marginBottom: "15px" }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              // id="outlined-basic"
              label="Organization"
              variant="outlined"
              fullWidth
              type="text"
              style={{ marginBottom: "15px" }}
              onChange={(e) => setOrganization(e.target.value)}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                value={role}
                onChange={handleChange}
                style={{ textAlign: "left", marginBottom: "15px" }}
                required
              >
                <MenuItem value={"User Researcher"}>User Researcher</MenuItem>
                <MenuItem value={"Product Manager"}>Product Manager</MenuItem>
                <MenuItem value={"Product Designer"}>Product Designer</MenuItem>
                <MenuItem value={"Market Researcher"}>
                  Market Researcher
                </MenuItem>
                <MenuItem value={"Founder/Co-Founder"}>
                  Founder/Co-Founder
                </MenuItem>
                <MenuItem value={"Customer Success/Support"}>
                  Customer Success/Support
                </MenuItem>
                <MenuItem value={"Sales and Marketing"}>
                  Sales and Marketing
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              // id="outlined-basic"
              label="Password"
              variant="outlined"
              fullWidth
              type="password"
              required
              style={{ marginBottom: "15px" }}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="terms-and-services-box">
              <Checkbox {...label} required />
              <p
                onClick={() => {
                  navigation("/terms-conditions");
                }}
              >
                I agree to the Terms of Service and Privacy Policy
              </p>
            </div>
            <div className="signup-buttons">
              <button type="submit">Signup</button>
              <div className="or-line">
                <div></div>
                <span>Or</span>
                <div></div>
              </div>
              <button
                className="signup-google-button"
                onClick={googleSignupHandler}
              >
                <FcGoogle size={35} /> Signup with Google
              </button>
              <p
                style={{
                  width: "100%",
                  color: "black",
                  fontSize: "17px",
                  marginTop: "20px",
                }}
              >
                Already have an account?{" "}
                <span
                  style={{ color: "#3E1F79", cursor: "pointer" }}
                  onClick={() => navigation("/login")}
                >
                  Login
                </span>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
