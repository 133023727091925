import React from "react";
import "./Header.css";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  return (
    <div className="nav-container fixed-top">
      <div className="logo-container">
        <img src={logo} alt="logo" />
      </div>
    </div>
  );
}
